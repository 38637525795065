.tx-media-stage {
  margin: 60px 0;
}
.tx-media-stage + .tx-media-stage {
  margin-top: 120px;
}
.tx-media-stage .slick-dots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.tx-media-stage .slick-dots,
section > .wrapper .tx-media-stage .slick-dots:not(.progress):not(.year-navigation) {
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
}
.tx-media-stage .slick-dots li {
  line-height: 100%;
  margin: 0 10px;
}
.tx-media-stage .slick-dots button {
  border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  height: 34px;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: -9999px;
  width: 43px;
}
.tx-media-stage .slick-dots button:after {
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  background-color: #bcbcbc;
  content: '';
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100%;
}
.tx-media-stage .slick-dots button:before {
  display: none !important;
}
.tx-media-stage .slick-dots button:hover:after {
  background-color: #7c7c7c;
}
.tx-media-stage .slick-dots .slick-active button:after {
  background-color: #0080bc;
}
.tx-media-stage-inner {
  position: relative;
}
.tx-media-stage-headline {
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background: #0080bc;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  left: 50%;
  line-height: 23px;
  padding: 15px 25px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  z-index: 5;
}
.tx-media-stage-canvas {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 588px;
  width: 100%;
}
.tx-media-stage-canvas.tx-media-stage-canvas-shadow {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.35);
  -ms-box-shadow: 0 0 50px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.35);
}
.tx-media-stage-elements {
  background: #ffffff;
  flex-grow: 1;
  width: 1px;
}
.tx-media-stage .slick-list,
.tx-media-stage .slick-track,
.tx-media-stage-element {
  height: 100%;
}
.tx-media-stage-element {
  position: relative;
}
.tx-media-stage-element img,
.row > div:first-child .tx-media-stage-element img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.tx-media-stage-element-overlay {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #ffffff;
  bottom: 80px;
  left: 0;
  padding: 14px 20px 15px;
  position: absolute;
  width: 425px;
}
.tx-media-stage-element-overlay-headline {
  color: #0080bc;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 11px;
  text-transform: uppercase;
}
.tx-media-stage-element-overlay-headline:only-child {
  margin-bottom: 0;
}
.tx-media-stage-element-overlay-text {
  color: #424242;
  font-size: 14px;
  line-height: 20px;
}
.tx-media-stage-element-image-container {
  display: block;
  height: 100%;
  width: 100%;
}
.tx-media-stage-element-image-container.objectfit-fallback {
  background-size: cover;
  background-position: center center;
}
.tx-media-stage-element-image-container.objectfit-fallback img {
  opacity: 0;
}
.tx-media-stage-element-image-container img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.no-objectfit .tx-media-stage-element-image-container img {
  opacity: 0;
}
.tx-media-stage-pagination {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #0080bc;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 20px;
  position: relative;
  width: 283px;
}
.tx-media-stage-pagination .slick-arrow {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -webkit-transition: all 200ms;
  background: #0080bc;
  border: 0;
  height: 40px;
  left: 50%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-indent: -9999px;
  width: 40px;
}
.tx-media-stage-pagination .slick-arrow:before {
  display: none !important;
}
.tx-media-stage-pagination .slick-arrow:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  border: 2px solid #ffffff;
  content: '';
  display: block;
  height: 30%;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 30%;
}
.tx-media-stage-pagination .slick-arrow.slick-disabled {
  opacity: 0;
  visibility: hidden;
}
.tx-media-stage-pagination .slick-prev {
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  top: 0;
}
.tx-media-stage-pagination .slick-prev:after {
  border-bottom: 0;
  border-right: 0;
}
.tx-media-stage-pagination .slick-prev.slick-disabled {
  transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
}
.tx-media-stage-pagination .slick-next {
  transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
  bottom: 0;
}
.tx-media-stage-pagination .slick-next:after {
  border-left: 0;
  border-top: 0;
}
.tx-media-stage-pagination .slick-next.slick-disabled {
  transform: translate(-50%, 50%) scale(0);
  -moz-transform: translate(-50%, 50%) scale(0);
  -ms-transform: translate(-50%, 50%) scale(0);
  -webkit-transform: translate(-50%, 50%) scale(0);
}
.tx-media-stage-pagination .slick-slide {
  border: 0;
}
.tx-media-stage-pagination .slick-list {
  height: 100% !important;
}
.tx-media-stage-pagination-inner {
  height: 100%;
  overflow: hidden;
}
.tx-media-stage-pagination-element {
  height: 170px !important;
  margin-bottom: 19px;
}
.tx-media-stage-pagination-element-inner {
  border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: block;
  height: 100%;
  width: 100%;
  background: #00477f;
  border: 5px solid #00477f;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.tx-media-stage-pagination-element-inner.objectfit-fallback {
  background-size: cover;
  background-position: center center;
}
.tx-media-stage-pagination-element-inner.objectfit-fallback img {
  opacity: 0;
}
.tx-media-stage-pagination-element-inner img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.no-objectfit .tx-media-stage-pagination-element-inner img {
  opacity: 0;
}
.tx-media-stage-pagination-element-inner img,
.row > div:first-child .tx-media-stage-pagination-element-inner img {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.tx-media-stage-pagination-element-inner:before {
  display: none !important;
}
.slick-current .tx-media-stage-pagination-element-inner {
  border-color: #ffffff;
}
@media screen and (max-width: 1024px) {
  .tx-media-stage-pagination .slick-arrow {
    left: auto;
    top: 50%;
  }
  .tx-media-stage-pagination .slick-prev {
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    left: 0;
  }
  .tx-media-stage-pagination .slick-prev:after {
    border-bottom: 2px solid #ffffff;
    border-right: 0;
    border-top: 0;
  }
  .tx-media-stage-pagination .slick-next {
    transform: translate(50%, -50%);
    -moz-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    right: 0;
  }
  .tx-media-stage-pagination .slick-next:after {
    border-bottom: 0;
    border-top: 2px solid #ffffff;
  }
  .tx-media-stage-pagination .slick-track {
    margin-left: 0;
    margin-right: 0;
  }
  .tx-media-stage-canvas {
    display: block;
    height: auto;
  }
  .tx-media-stage-elements,
  .tx-media-stage-pagination {
    width: 100%;
  }
  .tx-media-stage .slick-list,
  .tx-media-stage-element {
    height: auto;
  }
  .tx-media-stage-element-image-container,
  .tx-media-stage-element-image-container img {
    height: auto;
    object-fit: fill;
    width: auto;
  }
  .tx-media-stage-element-image-container img {
    width: 100%;
  }
  .tx-media-stage-element-overlay {
    padding: 30px 20px;
    position: static;
    width: 100%;
  }
  .tx-media-stage-pagination-inner {
    margin-left: -10px;
    margin-right: -10px;
  }
  .tx-media-stage-pagination-element {
    height: 140px !important;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .tx-media-stage-pagination-element {
    height: 100px !important;
  }
}
@media screen and (max-width: 479px) {
  .tx-media-stage-pagination {
    display: none;
  }
}
